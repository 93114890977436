import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import PageHero from '../components/PageHero';
// import Section from '../components/Section';
import CTA from '../components/CTA';
import BlogSection from '../components/BlogSection';

import { Section } from '../components/bulma';

export const PricingPageTemplate = ({
	image,
	title,
	heading,
	description,
	intro,
	main,
	testimonials,
	fullImage,
	pricing
}) => (
	<div>
		<br />
		<br />
		<PageHero
			title="Flexible pricing for every business"
			subtitle="Start free and scale as your payments business grows."
			image="image"
			signup
		/>
		<br />
		<section>
			<div className="container">
				<div className="columns">
					<div className="column">
						<div className="box has-text-left">
							<div className="content ">
								<h1 className="title is-size-3">Independent Agent</h1>
								<h2 className="subtitle is-size-5">Free</h2>
								<h3>Revenue share</h3>
								<a href="https://dashboard.unmerchant.com/" className="button is-primary is-small">
									<strong>Start free</strong>
								</a>
							</div>
						</div>
					</div>
					<div className="column">
						<div className="box has-text-left">
							<div className="content ">
								<h1 className="title is-size-3">Business</h1>
								<h2 className="subtitle is-size-5">$5,000/month</h2>
								<h3>5¢ per transaction</h3>
								<a href="https://dashboard.unmerchant.com/" className="button is-primary is-small">
									<strong>Get started</strong>
								</a>
							</div>
						</div>
					</div>

					<div className="column">
						<div className="box has-text-left">
							<div className="content ">
								<h1 className="title is-size-3">Entreprise</h1>
								<h2 className="subtitle is-size-5">Customized pricing</h2>
								<h3>Volume-based pricing</h3>
								<a href="https://calendly.com/finhub/demo" className="button is-primary is-small">
									<strong>Book demo</strong>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<br />
		<br />
		<br />
		<br />

		{/* <Section picturePos="right" bgColor="white" image="image" />
		<Section picturePos="left" bgColor="light" image="image" />
		<Section picturePos="right" bgColor="white" image="image" /> */}
		{/* <BlogSection title="Get more out of Unmerchant" /> */}
		<Section>
			<CTA />
		</Section>
	</div>
);

PricingPageTemplate.propTypes = {
	image: PropTypes.oneOfType([ PropTypes.object, PropTypes.string ]),
	title: PropTypes.string,
	heading: PropTypes.string,
	description: PropTypes.string,
	intro: PropTypes.shape({
		blurbs: PropTypes.array
	}),
	main: PropTypes.shape({
		heading: PropTypes.string,
		description: PropTypes.string,
		image1: PropTypes.oneOfType([ PropTypes.object, PropTypes.string ]),
		image2: PropTypes.oneOfType([ PropTypes.object, PropTypes.string ]),
		image3: PropTypes.oneOfType([ PropTypes.object, PropTypes.string ])
	}),
	testimonials: PropTypes.array,
	fullImage: PropTypes.oneOfType([ PropTypes.object, PropTypes.string ]),
	pricing: PropTypes.shape({
		heading: PropTypes.string,
		description: PropTypes.string,
		plans: PropTypes.array
	})
};

const PricingPage = ({ data }) => {
	const { frontmatter } = data.markdownRemark;

	return (
		<Layout>
			<PricingPageTemplate
				image={frontmatter.image}
				title={frontmatter.title}
				heading={frontmatter.heading}
				description={frontmatter.description}
				intro={frontmatter.intro}
				main={frontmatter.main}
				testimonials={frontmatter.testimonials}
				fullImage={frontmatter.full_image}
				pricing={frontmatter.pricing}
			/>
		</Layout>
	);
};

PricingPage.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.shape({
			frontmatter: PropTypes.object
		})
	})
};

export default PricingPage;

export const pricingPageQuery = graphql`
	query PricingPage($id: String!) {
		markdownRemark(id: { eq: $id }) {
			frontmatter {
				title
				image {
					childImageSharp {
						fluid(maxWidth: 2048, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				heading
				description
				intro {
					blurbs {
						image {
							childImageSharp {
								fluid(maxWidth: 240, quality: 64) {
									...GatsbyImageSharpFluid_withWebp
								}
							}
						}
						text
					}
					heading
					description
				}
				main {
					heading
					description
					image1 {
						alt
						image {
							childImageSharp {
								fluid(maxWidth: 526, quality: 92) {
									...GatsbyImageSharpFluid_withWebp
								}
							}
						}
					}
					image2 {
						alt
						image {
							childImageSharp {
								fluid(maxWidth: 526, quality: 92) {
									...GatsbyImageSharpFluid_withWebp
								}
							}
						}
					}
					image3 {
						alt
						image {
							childImageSharp {
								fluid(maxWidth: 1075, quality: 72) {
									...GatsbyImageSharpFluid_withWebp
								}
							}
						}
					}
				}
				testimonials {
					author
					quote
				}
				full_image {
					childImageSharp {
						fluid(maxWidth: 2048, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				pricing {
					heading
					description
					plans {
						description
						items
						plan
						price
					}
				}
			}
		}
	}
`;
